<template>
  <div class="card-one overflow-auto flex flex-col w-full md:w-1/2 lg:w-1/3 py-6 bg-white rounded-xl mx-2 px-4 font-bold" style="max-height: 600px;" >
    <div class="py-4 flex items-center h-14 justify-between">
  <a style="color: rgba(81, 79, 95, 1)" class="text-l ps-3" href="#">
    DM Settings
  </a>
  <div class="bg-white p-4 rounded-xl border border-gray-100 flex items-center justify-between">
    <div>
      <!-- Assurez-vous que le balisage du SVG est correct -->
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 30 30">
        <path d="M7 4C6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688L4.2929688 6.2929688C3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312L11.585938 15L4.2929688 22.292969C3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031L6.2929688 25.707031C6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031L15 18.414062L22.292969 25.707031C22.682969 26.098031 23.317031 26.098031 23.707031 25.707031L25.707031 23.707031C26.098031 23.316031 26.098031 22.682969 25.707031 22.292969L18.414062 15L25.707031 7.7070312C26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688L23.707031 4.2929688C23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688L15 11.585938L7.7070312 4.2929688C7.5115312 4.0974687 7.255875 4 7 4z"/>
      </svg>
    </div>
  </div>
</div>

    <div class="text-l py-3 px-3 bg-gray-100 rounded-xl flex items-center justify-between mt-4">
      <a class="flex bg-gradient-to-br from-teal-500 to-blue-500 text-transparent bg-clip-text" href="#">
        <svg class="me-4 text-xl bg-gradient-to-br from-teal-500 to-blue-500 text-transparent bg-clip-text"  xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 21 20" fill="none">
          <path
            d="M10.432 5.50187C10.804 5.41943 11.0945 5.1289 11.1769 4.75692L12.0465 0.844682C12.2742 -0.179998 13.7347 -0.179998 13.9624 0.844682L14.832 4.75692C14.9145 5.1289 15.205 5.41943 15.577 5.50187L19.4892 6.37148C20.5139 6.59918 20.5139 8.05965 19.4892 8.28735L15.577 9.15696C15.205 9.2394 14.9145 9.52992 14.832 9.90191L13.9624 13.8141C13.7347 14.8388 12.2742 14.8388 12.0465 13.8141L11.1769 9.90191C11.0945 9.52992 10.804 9.2394 10.432 9.15696L6.51973 8.28735C5.49505 8.05965 5.49505 6.59918 6.51973 6.37148L10.432 5.50187Z"
            fill="#266AE3"></path>
          <path
            d="M2.89726 14.325C3.43793 14.2052 3.86019 13.7829 3.98002 13.2422L4.36662 11.5018C4.50499 10.877 5.39517 10.877 5.53354 11.5018L5.92014 13.2422C6.03997 13.7829 6.46223 14.2052 7.0029 14.325L8.7433 14.7116C9.36814 14.85 9.36814 15.7401 8.7433 15.8785L7.0029 16.2651C6.46223 16.3849 6.03997 16.8072 5.92014 17.3479L5.53354 19.0883C5.39517 19.7131 4.50499 19.7131 4.36662 19.0883L3.98002 17.3479C3.86019 16.8072 3.43793 16.3849 2.89726 16.2651L1.15686 15.8785C0.532024 15.7401 0.532024 14.85 1.15686 14.7116L2.89726 14.325Z"
            fill="#266AE3"></path>
        </svg>
        DM Tour guide
      </a>
      <div class="bg-white rounded-full p-[1px] ">
        <div class="h-6 w-6">
          <svg class="" width="24" height="24" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="#2361E6"/>
      <stop offset="100%" stop-color="#40BEBE"/>
    </linearGradient>
  </defs>
  <circle cx="18" cy="18" r="15.91549431" fill="none" stroke="#2361E6" stroke-width="4"/>
  <circle cx="18" cy="18" r="15.91549431" fill="none" stroke="#e6e6e6" stroke-width="4" stroke-dasharray="25, 50"/>
  <text x="13" y="23.35" fill="#2361E6" font-size="18" text-anchor="middle"  font-family="Arial" font-weight="bold">4</text>
  <text x="18" y="23.35" fill="#b3b3b3" font-size="13"  font-family="Arial">/8</text>
</svg>

        </div>
      </div>
    </div>


    <div class="flex flex-col mt-6 text-gray-600 ms-3 text-sm font-bold">
      <span class="text-gray-300 text-xs">MANAGE BOX</span>

      <a class="flex items-center mt-8 text-sm font-bold" href="#">
              <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M2.5 12.4404H5.88197C6.56717 12.4404 7.19357 12.8276 7.5 13.4404C7.80643 14.0533 8.43283 14.4404 9.11803 14.4404H14.882C15.5672 14.4404 16.1936 14.0533 16.5 13.4404C16.8064 12.8276 17.4328 12.4404 18.118 12.4404H21.5M8.96656 4.44043H15.0334C16.1103 4.44043 16.6487 4.44043 17.1241 4.60439C17.5445 4.74939 17.9274 4.98603 18.2451 5.29718C18.6043 5.64903 18.8451 6.13063 19.3267 7.0938L21.4932 11.4269C21.6822 11.8049 21.7767 11.9939 21.8434 12.192C21.9026 12.3679 21.9453 12.5489 21.971 12.7327C22 12.9397 22 13.151 22 13.5736V15.6404C22 17.3206 22 18.1607 21.673 18.8024C21.3854 19.3669 20.9265 19.8258 20.362 20.1134C19.7202 20.4404 18.8802 20.4404 17.2 20.4404H6.8C5.11984 20.4404 4.27976 20.4404 3.63803 20.1134C3.07354 19.8258 2.6146 19.3669 2.32698 18.8024C2 18.1607 2 17.3206 2 15.6404V13.5736C2 13.151 2 12.9397 2.02897 12.7327C2.05471 12.5489 2.09744 12.3679 2.15662 12.192C2.22326 11.9939 2.31776 11.8049 2.50675 11.4269L4.67331 7.0938C5.1549 6.13062 5.3957 5.64903 5.75495 5.29718C6.07263 4.98603 6.45551 4.74939 6.87589 4.60439C7.35125 4.44043 7.88969 4.44043 8.96656 4.44043Z"
                  stroke="#7E7D84" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M21.5 19.207L22 12.707H17.5L15.5 14.207H8L6.5 12.707H2V13.707V15.707L1.5 16.707L2 18.707L3.5 20.207H15.5H19L21.5 19.207Z"
                  fill="#7E7D84"></path>
              </svg>
              Inbox
            </a>
            <a class="flex items-center mt-8 text-sm font-bold" href="#">
              <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M2.5 12.4404H5.88197C6.56717 12.4404 7.19357 12.8276 7.5 13.4404C7.80643 14.0533 8.43283 14.4404 9.11803 14.4404H14.882C15.5672 14.4404 16.1936 14.0533 16.5 13.4404C16.8064 12.8276 17.4328 12.4404 18.118 12.4404H21.5M8.96656 4.44043H15.0334C16.1103 4.44043 16.6487 4.44043 17.1241 4.60439C17.5445 4.74939 17.9274 4.98603 18.2451 5.29718C18.6043 5.64903 18.8451 6.13063 19.3267 7.0938L21.4932 11.4269C21.6822 11.8049 21.7767 11.9939 21.8434 12.192C21.9026 12.3679 21.9453 12.5489 21.971 12.7327C22 12.9397 22 13.151 22 13.5736V15.6404C22 17.3206 22 18.1607 21.673 18.8024C21.3854 19.3669 20.9265 19.8258 20.362 20.1134C19.7202 20.4404 18.8802 20.4404 17.2 20.4404H6.8C5.11984 20.4404 4.27976 20.4404 3.63803 20.1134C3.07354 19.8258 2.6146 19.3669 2.32698 18.8024C2 18.1607 2 17.3206 2 15.6404V13.5736C2 13.151 2 12.9397 2.02897 12.7327C2.05471 12.5489 2.09744 12.3679 2.15662 12.192C2.22326 11.9939 2.31776 11.8049 2.50675 11.4269L4.67331 7.0938C5.1549 6.13062 5.3957 5.64903 5.75495 5.29718C6.07263 4.98603 6.45551 4.74939 6.87589 4.60439C7.35125 4.44043 7.88969 4.44043 8.96656 4.44043Z"
                  stroke="#7E7D84" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path
                d="M21.5 19.207L22 12.707H17.5L15.5 14.207H8L6.5 12.707H2V13.707V15.707L1.5 16.707L2 18.707L3.5 20.207H15.5H19L21.5 19.207Z"
                fill="#7E7D84"></path>
            </svg>
            Discussion Rules
          </a>
  
          <a class="flex items-center mt-8 text-sm font-bold" href="#">
            <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.99721 9.03143H0.649452C0.290769 9.03143 0 8.7416 0 8.38408V7.08938C0 6.73186 0.29077 6.44203 0.649452 6.44203H3.08324C3.52825 4.03239 5.64665 2.20677 8.19283 2.20677H15.8044C18.6738 2.20677 21 4.5254 21 7.38556V17.028C21 19.8881 18.6738 22.2068 15.8044 22.2068H8.19283C5.64669 22.2068 3.52832 20.3812 3.08326 17.9716H0.649509C0.290827 17.9716 5.74256e-05 17.6818 5.74256e-05 17.3243V16.0296C5.74256e-05 15.6721 0.290827 15.3822 0.649509 15.3822H2.99721V13.5015H0.649509C0.290827 13.5015 5.74256e-05 13.2117 5.74256e-05 12.8542V11.5595C5.74256e-05 11.2019 0.290827 10.9121 0.649509 10.9121H2.99721V9.03143ZM11.9984 10.4761C13.2228 10.4761 14.2152 9.48679 14.2152 8.26645C14.2152 7.04611 13.2228 6.05683 11.9984 6.05683C10.7741 6.05683 9.78165 7.04611 9.78165 8.26645C9.78165 9.48679 10.7741 10.4761 11.9984 10.4761ZM16.3309 15.6461C16.3309 17.0632 14.4019 18.2119 12.0224 18.2119C9.64286 18.2119 7.72226 17.2502 7.66606 15.6694C7.5351 13.9516 9.64286 12.7106 12.0224 12.7106C14.4019 12.7106 16.4617 14.1022 16.3309 15.6461Z"
                fill="#7E7D84"></path>
            </svg>
            Adress Book
          </a>
  
          <a class="flex items-center mt-8 text-sm font-bold" href="#">
            <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path
                d="M12.0075 24.3657C12.6854 24.3644 13.3521 24.3072 14.0002 24.1988V17.2126L18.9532 22.1528C19.5002 21.7642 20.012 21.3321 20.4851 20.8601C20.9585 20.3881 21.393 19.8763 21.7825 19.3309L16.8293 14.3907H23.8339C23.9426 13.7444 24 13.0818 24 12.4044V12.3882C24 11.7107 23.9426 11.047 23.8339 10.4007H16.8282L21.7825 5.46171C21.393 4.91627 20.9585 4.40443 20.4865 3.93245H20.4851C20.012 3.46047 19.5002 3.0271 18.9546 2.63981L14.0002 7.58001V0.593752C13.3533 0.485392 12.6866 0.428186 12.0087 0.426756H11.9913C11.3132 0.428186 10.6491 0.485392 10.001 0.593752V7.58001L5.04804 2.63981C3.95419 3.41565 2.99788 4.3708 2.21868 5.46171L7.17185 10.4007H0.16733C0.0574454 11.0482 0 11.7145 0 12.3919V12.4267C0.00113864 12.6025 0.0126506 12.8173 0.0291855 13.0395L0.0342912 13.1063C0.0832496 13.7318 0.16733 14.3907 0.16733 14.3907H7.17185L2.21868 19.3309C2.60697 19.8763 3.04031 20.3869 3.51346 20.8576L3.51585 20.8601C3.98804 21.3321 4.501 21.7642 5.04804 22.1528L10.001 17.2126V24.1988C10.6491 24.3072 11.3146 24.3644 11.9937 24.3657H12.0075ZM12.0063 15.3882H11.9949C11.1034 15.3882 10.2496 15.225 9.46037 14.9287C9.16456 14.1428 9.0008 13.2911 8.99961 12.4019V12.3907C9.0008 11.5015 9.16456 10.6497 9.46156 9.86393C10.2496 9.56753 11.1034 9.40439 11.9949 9.40439H12.0063C12.8978 9.40439 13.7516 9.56753 14.5396 9.86393C14.8366 10.651 15.0004 11.5015 15.0004 12.3907V12.4019C15.0004 13.2911 14.8366 14.1428 14.5396 14.9287C13.7516 15.225 12.8978 15.3882 12.0063 15.3882Z"
                fill="#7E7D84"></path>
            </svg>
            Zapier
          </a>
    </div>

    <div class="flex flex-col my-8 text-s font-bold ms-3">
      <span class="text-gray-300 text-xs">ADVANCED MESSAGES</span>

          <div class="flex text-gray-400 text-caption mt-5">
            <img class="me-4" :src="image1" alt="" width="25"> Boost DM® <div class="text-caption ml-auto text-xs">Coming Soon...</div>
          </div>  
          <div class="flex text-gray-400 mt-5">
            <img class="me-4" :src="image2" alt="" width="25"> Blurred DM® <div class="text-caption ml-auto text-xs">Coming Soon...</div>
          </div>  
    </div>



  </div>
</template>
<script>
export default {
  name: 'CardOne',
  data() {
      return {
        image1: require('@/assets/icon-boost.svg'),
        image2: require('@/assets/icon-blurred.svg'),
      }
    }
}
</script>
<style>
.card-one::-webkit-scrollbar {
    width: 0 !important
}
.card-one {
    overflow: -moz-scrollbars-none;
}
.card-one {
    -ms-overflow-style: none;
}
</style>