<template>
  <div class="card-two flex flex-col items-center  h-full w-full  overflow-auto  rounded-xl font-bold" style="background-color: #f9f9fd;max-height:600px ;">
    <div data-v-460eaef9="" class="px-5 pt-3 lg:pt-5 pb-5 flex flex-1 items-center custom-scrollbar">
      <div id="confettis-area" class="flex w-full max-w-lg flex-col items-center">
        <div class="w-full max-w-[482px] pb-10">
          <div>
            <div class="flex  sm:flex-row justify-between items-center w-full">
              <h3 class="flex items-center gap-2 flex-shrink-0">
                <svg width="22" height="21" viewBox="0 0 22 21"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.2657 6.00187C11.6377 5.91943 11.9282 5.6289 12.0106 5.25692L12.8802 1.34468C13.1079 0.320002 14.5684 0.320002 14.7961 1.34468L15.6657 5.25692C15.7482 5.6289 16.0387 5.91943 16.4107 6.00187L20.3229 6.87148C21.3476 7.09918 21.3476 8.55965 20.3229 8.78735L16.4107 9.65696C16.0387 9.7394 15.7482 10.0299 15.6657 10.4019L14.7961 14.3141C14.5684 15.3388 13.1079 15.3388 12.8802 14.3141L12.0106 10.4019C11.9282 10.0299 11.6377 9.7394 11.2657 9.65696L7.35347 8.78735C6.32879 8.55965 6.32879 7.09918 7.35347 6.87148L11.2657 6.00187Z"
                    fill="url(#paint0_linear_1399_38346)"></path>
                  <path
                    d="M3.73149 14.825C4.27216 14.7052 4.69442 14.2829 4.81425 13.7422L5.20085 12.0018C5.33922 11.377 6.2294 11.377 6.36777 12.0018L6.75437 13.7422C6.8742 14.2829 7.29646 14.7052 7.83713 14.825L9.57753 15.2116C10.2024 15.35 10.2024 16.2401 9.57753 16.3785L7.83713 16.7651C7.29646 16.8849 6.8742 17.3072 6.75437 17.8479L6.36777 19.5883C6.2294 20.2131 5.33922 20.2131 5.20085 19.5883L4.81425 17.8479C4.69442 17.3072 4.27216 16.8849 3.73149 16.7651L1.99109 16.3785C1.36625 16.2401 1.36625 15.35 1.99109 15.2116L3.73149 14.825Z"
                    fill="url(#paint1_linear_1399_38346)"></path>
                  <defs>
                    <linearGradient id="paint0_linear_1399_38346" x1="3.59849" y1="-3.4628" x2="21.809" y2="-2.90901"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#40BEBE"></stop>
                      <stop offset="1" stop-color="#2361E6"></stop>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1399_38346" x1="-0.232323" y1="9.15998" x2="10.4677" y2="9.48538"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#40BEBE"></stop>
                      <stop offset="1" stop-color="#2361E6"></stop>
                    </linearGradient>
                  </defs>
                </svg>
                <span class="text-2xl font-bold text-transparent !bg-clip-text inline-block"
                  style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);">Get
                  Started</span></h3>
              <div class="mt-4 sm:mt-0 flex items-center gap-2">
                <div class="flex items-center"><img class="w-[70px] shrink-0" :src="image4" alt=""></div>
                <div class="flex flex-col me-5"><span class="text-title-sm text-transparent !bg-clip-text inline-block font-bold"
                    style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);">You
                    earned 20 500</span><span class="inline-block text-zinc-600 text-caption text-xs !font-bold ms-1"> Total XP to earn :
                    12 000</span></div>
              </div>
            </div>
            <div class="my-2 w-full">
              <div id="progress-container" class="w-full h-3 flex items-center rounded-full bg-white">
                <span v-if="radioChecked1" id="progress-bar" class="w-1/2 p-1 rounded-s-full  duration-200" style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);"></span>
                <span v-if="radioChecked2" id="progress-bar" class="w-1/2 p-1 rounded-e-full  duration-200" style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);"></span>
              </div>
            </div>
          </div>
          <div class="mt-8 flex flex-col gap-8">
            <div class="task-card dm-card !p-0 bg-white rounded-2xl">
              <div>
                <div class="pt-2 flex justify-center"><img class="w-full px-16" :src="task1" alt=""></div>
                <!-- <div class="w-full">
                  <svg viewBox="0 0 484 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_1399_39135)">
                      <path d="M-4 12.0003L98.2669 12.0003L238.239 12.0003L364.305 12.0003L487 11.9999"
                        stroke="url(#paint0_linear_1399_39135)" stroke-width="1.5" stroke-linecap="round"></path>
                    </g>
                    <defs>
                      <filter id="filter0_d_1399_39135" x="-14.15" y="0.85" width="511.3" height="20.3"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha">
                        </feColorMatrix>
                        <feOffset dy="-1"></feOffset>
                        <feGaussianBlur stdDeviation="4.7"></feGaussianBlur>
                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1399_39135"></feBlend>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1399_39135" result="shape">
                        </feBlend>
                      </filter>
                      <linearGradient id="paint0_linear_1399_39135" x1="-23.166" y1="6" x2="506.823" y2="8.41598"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"></stop>
                        <stop offset="0.37" stop-color="#2A77DD"></stop>
                        <stop offset="0.51103" stop-color="#38A2CA"></stop>
                        <stop offset="0.62" stop-color="#276DE2"></stop>
                        <stop offset="1" stop-color="white"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div> -->
                <div class="">
                  <div class="flex justify-between items-center px-8 gap-2">
                    <div class="flex gap-2 items-center">
                        <span class="inline-block">
                            <!-- Radio button -->
                          <div @click="isModalOpen = true" class="radio-button h-5 w-5 cursor-pointer rounded-full border border-blue-700 flex items-center justify-center">
                            <span v-if="radioChecked1" class="text-blue-700">
                                
                            <svg class="me-8" width="100%" height="100%" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.834 21C17.8045 21 21.834 16.9706 21.834 12C21.834 7.02944 17.8045 3 12.834 3C7.86342 3 3.83398 7.02944 3.83398 12C3.83398 16.9706 7.86342 21 12.834 21ZM16.3561 10.5384C16.6535 10.2501 16.6608 9.77525 16.3724 9.47789C16.0841 9.18053 15.6092 9.17322 15.3119 9.46158L11.709 12.9553L10.3561 11.6434C10.0587 11.355 9.58391 11.3624 9.29556 11.6597C9.0072 11.9571 9.01451 12.4319 9.31188 12.7203L11.1869 14.5384C11.4778 14.8205 11.9402 14.8205 12.2311 14.5384L16.3561 10.5384Z"
                                :fill="isActive ? 'url(#paint0_linear_active)' : 'url(#paint0_linear)'" />
                                <defs>
                                <linearGradient id="paint0_linear" x1="0.128303" y1="-2.01167" x2="22.7243"
                                    y2="-1.32451" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#ffffff"></stop>
                                    <stop offset="1" stop-color="#2361E6"></stop>
                                </linearGradient>
                                <linearGradient id="paint0_linear_active" x1="0.128303" y1="-2.01167" x2="22.7243"
                                    y2="-1.32451" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#40BEBE"></stop>
                                    <stop offset="1" stop-color="#2361E6"></stop>
                                </linearGradient>
                                </defs>
                            </svg>
                            </span>
                          </div>
                        </span>
                      <div :class="{'line-through': radioChecked1, 'text-title-lg': true, 'flex': true}">Create more inbox  </div>
                      <!-- <svg  class=" font-bold" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                      </svg> -->
                    </div>
                    <div class="text-title-lg text-transparent !bg-clip-text flex items-center gap-1.5 font-bold"
                      style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.16602 6.35417L4.97854 9.89961L11.166 2.09961"
                            stroke="url(#paint0_linear_1399_39131)" stroke-width="3" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                          <defs>
                            <linearGradient id="paint0_linear_1399_39131" x1="0.313175" y1="-0.0721146" x2="11.6077"
                              y2="0.324202" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#40BEBE"></stop>
                              <stop offset="1" stop-color="#2361E6"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      <span><span>500</span><span class="text-title-sm p-1">XP</span></span>
                    </div>
                  </div>
                  <div class="ms-12 ps-6 !pb-4 mt-2">
                    <p class="text-xs text-zinc-500">Create more inbox, welcome your new contact by
                      a message</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="task-card dm-card  bg-white rounded-2xl ">
              <div>
                <div class="relative flex justify-center px-8 my-6">
                  <img class="w-full h-56 rounded-xl" :src="task2" alt="">

                  <svg class="play-button rounded-full" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <circle style="filter: blur(3px);    "  cx="32" cy="32" r="32" fill="gray"/>
                     <polygon points="26,20 44,32 26,44" fill="#fff" />
                  </svg>
                </div>
                <!-- <div class="w-full">
                  <svg viewBox="0 0 484 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_1399_39135)">
                      <path d="M-4 12.0003L98.2669 12.0003L238.239 12.0003L364.305 12.0003L487 11.9999"
                        stroke="url(#paint0_linear_1399_39135)" stroke-width="1.5" stroke-linecap="round"></path>
                    </g>
                    <defs>
                      <filter id="filter0_d_1399_39135" x="-14.15" y="0.85" width="511.3" height="20.3"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha">
                        </feColorMatrix>
                        <feOffset dy="-1"></feOffset>
                        <feGaussianBlur stdDeviation="4.7"></feGaussianBlur>
                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1399_39135"></feBlend>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1399_39135" result="shape">
                        </feBlend>
                      </filter>
                      <linearGradient id="paint0_linear_1399_39135" x1="-23.166" y1="6" x2="506.823" y2="8.41598"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"></stop>
                        <stop offset="0.37" stop-color="#2A77DD"></stop>
                        <stop offset="0.51103" stop-color="#38A2CA"></stop>
                        <stop offset="0.62" stop-color="#276DE2"></stop>
                        <stop offset="1" stop-color="white"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div> -->
                <div class="">
                  <div class="flex justify-between items-center px-8 gap-2">
                    <div class="flex gap-2 items-center">
                        <span class="inline-block">
                            <!-- Radio button -->
                          <div @click="showModal = true" class="radio-button h-5 w-5 cursor-pointer rounded-full border border-blue-700 flex items-center justify-center">
                            <span v-if="radioChecked2" class="text-blue-700">
                                
                            <svg  class="me-8" width="100%" height="100%" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.834 21C17.8045 21 21.834 16.9706 21.834 12C21.834 7.02944 17.8045 3 12.834 3C7.86342 3 3.83398 7.02944 3.83398 12C3.83398 16.9706 7.86342 21 12.834 21ZM16.3561 10.5384C16.6535 10.2501 16.6608 9.77525 16.3724 9.47789C16.0841 9.18053 15.6092 9.17322 15.3119 9.46158L11.709 12.9553L10.3561 11.6434C10.0587 11.355 9.58391 11.3624 9.29556 11.6597C9.0072 11.9571 9.01451 12.4319 9.31188 12.7203L11.1869 14.5384C11.4778 14.8205 11.9402 14.8205 12.2311 14.5384L16.3561 10.5384Z"
                                :fill="isActive ? 'url(#paint0_linear_active)' : 'url(#paint0_linear)'" />
                                <defs>
                                <linearGradient id="paint0_linear" x1="0.128303" y1="-2.01167" x2="22.7243"
                                    y2="-1.32451" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#ffffff"></stop>
                                    <stop offset="1" stop-color="#2361E6"></stop>
                                </linearGradient>
                                <linearGradient id="paint0_linear_active" x1="0.128303" y1="-2.01167" x2="22.7243"
                                    y2="-1.32451" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#40BEBE"></stop>
                                    <stop offset="1" stop-color="#2361E6"></stop>
                                </linearGradient>
                                </defs>
                            </svg>
                            </span>
                          </div>
                        </span>
                      <div :class="{'line-through': radioChecked2, 'text-title-lg': true, 'flex': true}">Manage your messaging rules                      
                        <svg  class="mt-1 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                        </svg>  
                      </div>

                    </div>
                    <div class="text-title-lg text-transparent !bg-clip-text flex items-center gap-1.5 font-bold"
                      style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);">


                      <svg  width="14" height="14" class="text-blue-400 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="M5 12h14m-7 7V5"/>
                      </svg>

                      <span><span>100</span><span class="text-title-sm p-1">XP</span></span>
                    </div>
                  </div>
                  <div class="ms-12 ps-6 !pb-4 mt-2">
                    <p class="text-xs text-zinc-500">Create more inbox, welcome your new contact by
                      a message</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div><!---->
      </div>
    </div>

          <!-- Modal -->
          <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="modal-overlay absolute inset-0 bg-black opacity-25"></div>

      <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl shadow-lg z-50 overflow-y-auto" style="background: rgba(255, 255, 255, 0.8);">
        <div class="modal-content py-4 justify-center text-center px-6">

          <!-- Contenu du modal -->
        <div class="mx-16">
          <svg width="257" height="202" viewBox="0 0 257 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddi_0_1)">
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint0_diamond_0_1)" />
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint1_diamond_0_1)" />
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint2_linear_0_1)" />
            </g>
            <g filter="url(#filter1_i_0_1)">
              <path
                d="M84.0086 138.646C82.0465 138.646 81.0654 138.646 80.224 138.319C79.5116 138.043 78.875 137.602 78.3666 137.031C77.7661 136.358 77.4223 135.439 76.7345 133.601L64.5839 101.136C64.2029 100.118 64.0124 99.6091 63.9387 99.0852C63.8761 98.6397 63.8761 98.1876 63.9387 97.742C64.0124 97.2181 64.2029 96.7091 64.5839 95.6912L76.7345 63.2261C77.4223 61.3885 77.7661 60.4697 78.3666 59.796C78.875 59.2256 79.5116 58.7841 80.224 58.5079C81.0654 58.1817 82.0465 58.1817 84.0086 58.1817L174.587 58.1817C176.6 58.1817 177.607 58.1817 178.464 58.5218C179.189 58.8097 179.834 59.2693 180.343 59.8613C180.944 60.5606 181.272 61.5121 181.929 63.4152L193.131 95.8803C193.458 96.8294 193.622 97.3039 193.685 97.7903C193.739 98.2041 193.739 98.6232 193.685 99.037C193.622 99.5234 193.458 99.9979 193.131 100.947L181.929 133.412C181.272 135.315 180.944 136.267 180.343 136.966C179.834 137.558 179.189 138.018 178.464 138.305C177.607 138.646 176.6 138.646 174.587 138.646L84.0086 138.646Z"
                fill="#E3F0FF" />
            </g>
            <g filter="url(#filter2_ddi_0_1)">
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint3_diamond_0_1)" />
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint4_diamond_0_1)" />
              <path
                d="M76.919 145.828C74.9262 145.828 73.9298 145.828 73.0789 145.493C72.3586 145.21 71.717 144.757 71.2083 144.174C70.6074 143.485 70.2727 142.547 69.6034 140.67L55.465 101.022C55.1168 100.045 54.9426 99.5571 54.8753 99.0558C54.8179 98.6293 54.8179 98.1971 54.8753 97.7706C54.9426 97.2693 55.1168 96.781 55.465 95.8044L69.6033 56.158C70.2727 54.2811 70.6074 53.3426 71.2083 52.6535C71.717 52.0701 72.3586 51.6178 73.0789 51.3346C73.9298 51 74.9262 51 76.9189 51L179.581 51C181.553 51 182.54 51 183.384 51.329C184.099 51.6075 184.737 52.0525 185.246 52.6272C185.847 53.3059 186.187 54.2312 186.869 56.0818L201.476 95.7288C201.846 96.7332 202.031 97.2353 202.103 97.7518C202.164 98.1911 202.164 98.6366 202.103 99.0758C202.031 99.5923 201.846 100.094 201.476 101.099L186.869 140.746C186.187 142.596 185.847 143.522 185.246 144.2C184.737 144.775 184.099 145.22 183.384 145.499C182.54 145.828 181.553 145.828 179.581 145.828L76.919 145.828Z"
                fill="url(#paint5_linear_0_1)" />
            </g>
            <g filter="url(#filter3_i_0_1)">
              <path
                d="M84.0086 138.646C82.0465 138.646 81.0654 138.646 80.224 138.319C79.5116 138.043 78.875 137.602 78.3666 137.031C77.7661 136.358 77.4223 135.439 76.7345 133.601L64.5839 101.136C64.2029 100.118 64.0124 99.6091 63.9387 99.0852C63.8761 98.6397 63.8761 98.1876 63.9387 97.742C64.0124 97.2181 64.2029 96.7091 64.5839 95.6912L76.7345 63.2261C77.4223 61.3885 77.7661 60.4697 78.3666 59.796C78.875 59.2256 79.5116 58.7841 80.224 58.5079C81.0654 58.1817 82.0465 58.1817 84.0086 58.1817L174.587 58.1817C176.6 58.1817 177.607 58.1817 178.464 58.5218C179.189 58.8097 179.834 59.2693 180.343 59.8613C180.944 60.5606 181.272 61.5121 181.929 63.4152L193.131 95.8803C193.458 96.8294 193.622 97.3039 193.685 97.7903C193.739 98.2041 193.739 98.6232 193.685 99.037C193.622 99.5234 193.458 99.9979 193.131 100.947L181.929 133.412C181.272 135.315 180.944 136.267 180.343 136.966C179.834 137.558 179.189 138.018 178.464 138.305C177.607 138.646 176.6 138.646 174.587 138.646L84.0086 138.646Z"
                fill="#E3F0FF" />
            </g>
            <g filter="url(#filter4_dd_0_1)">
              <path
                d="M95.8574 129.343C94.538 129.343 93.8784 129.343 93.302 129.133C92.8136 128.956 92.371 128.672 92.0067 128.301C91.5766 127.864 91.3024 127.264 90.7538 126.064L79.605 101.679C79.2085 100.812 79.0102 100.378 78.9339 99.9261C78.8691 99.542 78.8691 99.1497 78.9339 98.7657C79.0102 98.3136 79.2085 97.8799 79.605 97.0125L90.7538 72.6274C91.3024 71.4275 91.5766 70.8276 92.0066 70.3904C92.371 70.02 92.8136 69.7357 93.302 69.5584C93.8784 69.3491 94.538 69.3491 95.8573 69.3491L164.048 69.3491C165.414 69.3491 166.097 69.3491 166.689 69.5709C167.19 69.7587 167.641 70.0595 168.007 70.4498C168.439 70.9106 168.702 71.5411 169.228 72.8021L179.39 97.1872C179.725 97.992 179.893 98.3944 179.958 98.8112C180.013 99.1655 180.013 99.5262 179.958 99.8805C179.893 100.297 179.725 100.7 179.39 101.505L169.228 125.89C168.702 127.151 168.439 127.781 168.007 128.242C167.641 128.632 167.19 128.933 166.689 129.121C166.097 129.343 165.414 129.343 164.048 129.343L95.8574 129.343Z"
                fill="url(#paint6_diamond_0_1)" />
              <path
                d="M95.8574 129.343C94.538 129.343 93.8784 129.343 93.302 129.133C92.8136 128.956 92.371 128.672 92.0067 128.301C91.5766 127.864 91.3024 127.264 90.7538 126.064L79.605 101.679C79.2085 100.812 79.0102 100.378 78.9339 99.9261C78.8691 99.542 78.8691 99.1497 78.9339 98.7657C79.0102 98.3136 79.2085 97.8799 79.605 97.0125L90.7538 72.6274C91.3024 71.4275 91.5766 70.8276 92.0066 70.3904C92.371 70.02 92.8136 69.7357 93.302 69.5584C93.8784 69.3491 94.538 69.3491 95.8573 69.3491L164.048 69.3491C165.414 69.3491 166.097 69.3491 166.689 69.5709C167.19 69.7587 167.641 70.0595 168.007 70.4498C168.439 70.9106 168.702 71.5411 169.228 72.8021L179.39 97.1872C179.725 97.992 179.893 98.3944 179.958 98.8112C180.013 99.1655 180.013 99.5262 179.958 99.8805C179.893 100.297 179.725 100.7 179.39 101.505L169.228 125.89C168.702 127.151 168.439 127.781 168.007 128.242C167.641 128.632 167.19 128.933 166.689 129.121C166.097 129.343 165.414 129.343 164.048 129.343L95.8574 129.343Z"
                fill="url(#paint7_linear_0_1)" />
            </g>
            <path
              d="M113.818 98.7852C117.626 97.65 126.173 94.0013 128.922 83.6632C129.055 83.1626 129.803 83.142 129.963 83.6355C131.232 87.5447 135.03 95.683 145.205 98.7421C145.707 98.893 145.714 99.5585 145.213 99.7121C141.498 100.851 133.391 104.488 130.103 115.057C129.951 115.547 129.208 115.541 129.064 115.048C127.939 111.194 124.368 103.061 113.812 99.754C113.314 99.5981 113.317 98.9343 113.818 98.7852Z"
              fill="white" />
            <g filter="url(#filter5_f_0_1)">
              <path
                d="M113.818 98.7852C117.626 97.65 126.173 94.0013 128.922 83.6632C129.055 83.1626 129.803 83.142 129.963 83.6355C131.232 87.5447 135.03 95.683 145.205 98.7421C145.707 98.893 145.714 99.5585 145.213 99.7121C141.498 100.851 133.391 104.488 130.103 115.057C129.951 115.547 129.208 115.541 129.064 115.048C127.939 111.194 124.368 103.061 113.812 99.754C113.314 99.5981 113.317 98.9343 113.818 98.7852Z"
                fill="white" fill-opacity="0.9" />
            </g>
            <path d="M97 26.1818L98.875 28L103 24" stroke="url(#paint8_linear_0_1)" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
            <rect x="173.25" y="11.4135" width="9.83831" height="9.83831" transform="rotate(-19.6279 173.25 11.4135)"
              fill="#266AE3" />
            <rect x="7" y="102.459" width="9.83831" height="9.83831" transform="rotate(-71.881 7 102.459)"
              fill="#266AE3" />
            <path
              d="M215.066 80.4276C213.348 78.5257 214.28 75.4787 216.767 74.8623C219.325 74.2287 221.597 76.6259 220.826 79.1457C220.056 81.6654 216.833 82.3829 215.066 80.4276Z"
              fill="#266AE3" />
            <path
              d="M42.4713 19.2823C43.3848 17.6989 45.6571 17.662 46.6216 19.2149C47.6131 20.8114 46.485 22.8794 44.6059 22.9099C42.7268 22.9404 41.5321 20.9102 42.4713 19.2823Z"
              fill="#266AE3" />
            <circle cx="239.477" cy="96.3771" r="3.32886" stroke="#37A3C9" stroke-width="2" />
            <circle cx="78.1306" cy="36.9537" r="2.93532" stroke="#37A3C9" stroke-width="2" />
            <path
              d="M32.2858 49.1534L25.9893 54.4661M36.2212 63.5173L28.744 61.5497M22.054 68.83L25.9893 76.3072M13.9866 68.83L10.6415 76.3072M9.26418 61.5497L1 63.5173M11.4286 54.4661L4.93532 49.1534M18.5122 50.5308V42.0698"
              stroke="#37A3C9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M251.481 10.0836L245.185 15.3963M255.416 24.4475L247.939 22.4799M241.249 29.7602L245.185 37.2373M233.182 29.7602L229.837 37.2373M228.459 22.4799L220.195 24.4475M230.624 15.3963L224.131 10.0836M237.708 11.4609V3"
              stroke="#37A3C9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
              <filter id="filter0_ddi_0_1" x="1.98631" y="0.814397" width="253.008" height="200.52"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.66037" />
                <feGaussianBlur stdDeviation="22.1928" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.843137 0 0 0 0 0.886275 0 0 0 0 0.92549 0 0 0 0.34 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.66037" />
                <feGaussianBlur stdDeviation="26.423" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_0_1" result="effect2_dropShadow_0_1" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_1" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="9.73043" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_0_1" />
              </filter>
              <filter id="filter1_i_0_1" x="63.8917" y="58.1817" width="129.834" height="83.3584"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.89448" />
                <feGaussianBlur stdDeviation="1.44724" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
              </filter>
              <filter id="filter2_ddi_0_1" x="1.98631" y="0.814397" width="253.008" height="200.52"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.66037" />
                <feGaussianBlur stdDeviation="22.1928" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.843137 0 0 0 0 0.886275 0 0 0 0 0.92549 0 0 0 0.34 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.66037" />
                <feGaussianBlur stdDeviation="26.423" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_0_1" result="effect2_dropShadow_0_1" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_1" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="9.73043" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_0_1" />
              </filter>
              <filter id="filter3_i_0_1" x="63.8917" y="58.1817" width="129.834" height="83.3584"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.89448" />
                <feGaussianBlur stdDeviation="1.44724" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
              </filter>
              <filter id="filter4_dd_0_1" x="24.3396" y="17.5494" width="210.205" height="169.085"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.74593" />
                <feGaussianBlur stdDeviation="22.9066" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.87451 0 0 0 0 0.913725 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="2.74593" />
                <feGaussianBlur stdDeviation="27.2728" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_0_1" result="effect2_dropShadow_0_1" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_1" result="shape" />
              </filter>
              <filter id="filter5_f_0_1" x="110.248" y="80.0835" width="38.5308" height="38.5307"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.59643" result="effect1_foregroundBlur_0_1" />
              </filter>
              <radialGradient id="paint0_diamond_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(140.983 161.237) rotate(-107.47) scale(155.791 155.791)">
                <stop offset="0.421875" stop-color="#A349EF" />
                <stop offset="1" stop-color="#FF65E6" />
              </radialGradient>
              <radialGradient id="paint1_diamond_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(93.3717 116.49) rotate(-116.259) scale(74.3479 39.3607)">
                <stop stop-color="#FF9FEA" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </radialGradient>
              <linearGradient id="paint2_linear_0_1" x1="122.701" y1="54.5941" x2="154.989" y2="145.309"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#7A96AC" />
                <stop offset="0.18" stop-color="#EAEFF3" />
                <stop offset="0.315" stop-color="#C2D4E1" />
                <stop offset="0.491919" stop-color="white" />
                <stop offset="0.615" stop-color="#D4DEE5" />
                <stop offset="0.785" stop-color="#ABBDC8" />
                <stop offset="0.955" stop-color="#BCCAD7" />
              </linearGradient>
              <radialGradient id="paint3_diamond_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(140.983 161.237) rotate(-107.47) scale(155.791 155.791)">
                <stop offset="0.421875" stop-color="#A349EF" />
                <stop offset="1" stop-color="#FF65E6" />
              </radialGradient>
              <radialGradient id="paint4_diamond_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(93.3717 116.49) rotate(-116.259) scale(74.3479 39.3607)">
                <stop stop-color="#FF9FEA" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </radialGradient>
              <linearGradient id="paint5_linear_0_1" x1="122.701" y1="54.5941" x2="154.989" y2="145.309"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#7A96AC" />
                <stop offset="0.18" stop-color="#EAEFF3" />
                <stop offset="0.315" stop-color="#C2D4E1" />
                <stop offset="0.491919" stop-color="white" />
                <stop offset="0.615" stop-color="#D4DEE5" />
                <stop offset="0.785" stop-color="#ABBDC8" />
                <stop offset="0.955" stop-color="#BCCAD7" />
              </linearGradient>
              <radialGradient id="paint6_diamond_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(136.248 139.092) rotate(-107.47) scale(98.5625 98.5624)">
                <stop offset="0.421875" stop-color="#A349EF" />
                <stop offset="1" stop-color="#FF65E6" />
              </radialGradient>
              <linearGradient id="paint7_linear_0_1" x1="129.414" y1="69.3491" x2="129.414" y2="129.343"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#38A3CA" />
                <stop offset="0.09" stop-color="#39A6C9" />
                <stop offset="0.205" stop-color="#4193D9" />
                <stop offset="0.35" stop-color="#4191D8" />
                <stop offset="0.635" stop-color="#4391DB" />
                <stop offset="0.73" stop-color="#39A5CA" />
                <stop offset="0.9" stop-color="#3F93D8" />
                <stop offset="1" stop-color="#38A0CC" />
              </linearGradient>
              <linearGradient id="paint8_linear_0_1" x1="95.7648" y1="22.8863" x2="103.288" y2="23.2295"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#40BEBE" />
                <stop offset="1" stop-color="#2361E6" />
            </linearGradient>
          </defs>
        </svg>
        </div>
        <div class="text-2xl font-bold  text-center">Congratulations You’ve Earned</div>
        <div class="text-center text-2xl font-bold bg-gradient-to-br from-teal-500 to-blue-500 text-transparent bg-clip-text" >500 XP</div>
        <button @click="confirmSelection1" class=" text-white w-1/3 py-2 px-4 rounded mt-4 bg-gradient-to-br from-teal-500 to-blue-500 text-transparent">Yahhhh</button>

      </div>
    </div>
  </div>





  <!-- modal2 -->
        <!-- Modal -->
        <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="modal-overlay absolute inset-0 bg-black opacity-25"></div>

        <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl shadow-lg z-50 overflow-y-auto"
          style="background: rgba(255, 255, 255, 0.8);">
          <div class="modal-content py-4 justify-center text-center px-6">
            <!-- Contenu du modal -->
            <div class="text-center justify-center items-center">
              <svg class="mx-auto" width="40%" height="40%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.834 21C17.8045 21 21.834 16.9706 21.834 12C21.834 7.02944 17.8045 3 12.834 3C7.86342 3 3.83398 7.02944 3.83398 12C3.83398 16.9706 7.86342 21 12.834 21ZM16.3561 10.5384C16.6535 10.2501 16.6608 9.77525 16.3724 9.47789C16.0841 9.18053 15.6092 9.17322 15.3119 9.46158L11.709 12.9553L10.3561 11.6434C10.0587 11.355 9.58391 11.3624 9.29556 11.6597C9.0072 11.9571 9.01451 12.4319 9.31188 12.7203L11.1869 14.5384C11.4778 14.8205 11.9402 14.8205 12.2311 14.5384L16.3561 10.5384Z"
                  fill="url(#paint0_linear)"></path>
                <defs>
                  <linearGradient id="paint0_linear" x1="0.128303" y1="-2.01167" x2="22.7243" y2="-1.32451"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#40BEBE"></stop>
                    <stop offset="1" stop-color="#2361E6"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="text-xl w-full font-bold text-center my-5">Congratulations You’ve completed</div>
            <div class="text-center text-2xl font-bold bg-gradient-to-br from-teal-500 to-blue-500 text-transparent bg-clip-text">DM Tour guide</div>
            <div class="flex text-center mt-4">
              <span class="text-center mx-auto text-title-sm text-transparent !bg-clip-text inline-block font-bold"
                style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%); color: black;">
                Total XP earned <span class="ms-4 ">600</span><span class="text-xs ms-1">XP</span>
              </span>
            </div>
            <button @click="confirmSelection2"
              class="hover:bg-blue-600 text-white w-1/3 py-2 px-4 rounded mt-4"
              style="background: linear-gradient(91.74deg, rgb(64, 190, 190) -20.8%, rgb(35, 97, 230) 101.14%);">Done</button>
          </div>
        </div>
      </div>

  </div>
</template>

<script>

export default {
  name: 'CardTwo',
  data() {
    return {
      image4: require('@/assets/img_4.png'),
      task1: require('@/assets/task-1.svg'),
      task2: require('@/assets/image_5.png'),
      isModalOpen: false,
      radioChecked1: false,
      showModal: false,
      radioChecked2: false
    }
  },
  methods: {
    confirmSelection1() {
      this.radioChecked1 = true;
      this.isModalOpen = false;
    },
    confirmSelection2() {
      this.radioChecked2 = true;
      this.showModal = false;
    }
  }
}
// import { ref } from 'vue';

// const isModalOpen = ref(false);
// const showModal = ref(false);
// function openModal() {
//   isModalOpen.value = true;
// }



</script>
<style>
.radio-container {
  display: flex;
  align-items: center;
}

label {
  cursor: pointer;
  display: inline-block;
}

input[type="radio"] {
  display: none;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  box-shadow: 1px 3px 2px #42434b;
}
.card-two::-webkit-scrollbar {
    width: 0 !important
}
.card-two {
    overflow: -moz-scrollbars-none;
}
.card-two {
    -ms-overflow-style: none;
}

</style>
