<template>
  <div id="app" >
    <BlueCard />
  </div>
</template>

<script>
import BlueCard from './BlueCard.vue';

export default {
  name: 'App',
  components: {
    BlueCard
  }
}
</script>

<style>
/* Styles globaux ou spécifiques à App.vue */
</style>
